body {
  background: linear-gradient(45deg, #cc95c0 , #dbd4bd, #7aa1d2);
  background-repeat: no-repeat;
  background-attachment: fixed;
};
#img-home {
  
    margin-left: auto;
    display: block;
   
}
#toolbarCss {
  background: linear-gradient(45deg, #cc95c0 , #dbd4bd, #7aa1d2);
}

#drawerCss {
  /* background: linear-gradient(45deg, #cc95c0 , #dbd4bd, #7aa1d2); */
  background-color: whitesmoke;
}
/* .iconDetails {
  margin-left:2%;
 float:left; 
 height:40px;
 width:40px; 
 }  */
 
 .container2 {
     width:100%;
     height:auto;
     padding:1%;
 }

.menuLink {
  text-decoration: none;
}

.menuLink:visited, .menuLink:link, .menuLink:active {
  text-decoration: none;
  color: black;
}

.progress-container {
  max-width: 500px;
  width: 40px;
}

.progress-container h1 {
  text-align: center;
}

.progressbar-container {
  position: relative;
  width: 100%;
  height: 37px;
  border: 1px solid #000;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.progressbar-complete {
  position: absolute;
  left: 0;
  top: 0px;
  height: 100%;
  background-color: #48d62f;
  border-radius: 10px;
  animation: g 2500ms infinite ease-in-out;
  z-index: 2;
  
}

.progressbar-liquid {
  z-index: 1;
  width: 70px;
  height: 70px;
  animation: g 2500ms infinite ease-in-out,
    r 3000ms infinite cubic-bezier(0.5, 0.5, 0.5, 0.5);
  position: absolute;
  right: -5px;
  top: -10px;
  background-color: #48d62f;
  border-radius: 40%;
}

.progress {
  z-index: 2;
}

@keyframes g {
  0% {
    background-color: #48d62f;
  }
  25% {
    background-color: #cd3015;
  }
  50% {
    background-color: #1b92c1;
  }
  75% {
    background-color: #db720a;
  }
  100% {
    background-color: #8416e5;
  }
}

@keyframes r {
  from {
    transform: rotate(0deg);
  }
  from {
    transform: rotate(360deg);
  }
}

.img-wraps-ep {
  position: relative;
  display: inline-block;
  
  font-size: 0;
}
.img-wraps-ep .closes {
  position: absolute;
  right: 0px;
  z-index: 100;
  background: #0369e5;
  padding: 4px 3px;
   
  color: rgb(249, 248, 248);
  font-weight: bold;
  cursor: pointer;
  
  text-align: center;
  font-size: 22px;
  line-height: 10px;
  border-radius: 50%;
  border:1px solid rgb(250, 247, 247);
}
.img-wraps-ep:hover .closes {
  opacity: 1;
}